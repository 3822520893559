import { axiosNotes } from "common/api/axios/notes_axios";

export type AuthResponse = {
  userViewName: string;

  // Mkrstic
  userViewEmail: string;
  userViewPicture: string;
};

export const authCurrentGet = async (): Promise<AuthResponse> => {
  let authResponse: AuthResponse = {
    userViewName: "",
    userViewEmail: "",
    userViewPicture: "",
  };
  try {
    const { data } = await axiosNotes.get("dashboard", {
      withCredentials: true,
    });
    authResponse.userViewName = data.name;
    authResponse.userViewEmail = data.email;
    authResponse.userViewPicture = data.picture;
    console.log(
      "Email: " +
        authResponse.userViewEmail +
        ", " +
        authResponse.userViewPicture
    );
  } catch (err) {
    console.log("authCurrentGet axios err");
  }

  return authResponse;
};

export const authLogout = async (): Promise<boolean> => {
  let success: boolean = false;
  let response: any;

  const logout: string = "logout";
  console.log("LOGOUT Api Url: " + logout);

  try {

    response = await axiosNotes.post("/logout", {}, { withCredentials: true });

  } catch (error) {}
  if (response && response.status === 200) {
    success = true;
  }

  return success;
};
