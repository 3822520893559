import React, { useRef } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { QUERY_KEYS } from "constant/react_query";
import NoteForm from "components/common/note/NoteForm/NoteForm";
import { NoteResult } from "common/note/dto/NoteResult";
import NoteSingleNav from "components/common/note/NoteSingleNav";
import { noteFormDefaultValuesGet } from "common/note/note_form_common";
import { useNoteUpdate } from "hooks/note/useNoteUpdate";

type NoteEditProps = {
  note: NoteResult;
};

const NoteEdit = ({ note }: NoteEditProps) => {
  const { handleSubmit, reset, control } = useForm({
    defaultValues: noteFormDefaultValuesGet(note),
  });
  const { noteUpdateMutate } = useNoteUpdate({
    invalidateKeysArray: [[QUERY_KEYS.NOTE_LIST_GET], [QUERY_KEYS.NOTE_GET]],
  });
  const editorRef = React.useRef<any>(note.note);

  const onSubmit = async (data: any) => {
    console.log(data);

    const { title, author } = data;
    noteUpdateMutate({
      id: note.id,
      updatePayload: {
        title,
        author,
        note: editorRef.current.getContent(),
      },
    });
    toast.success("Note updated");
  };

  return (
    <DivNote>
      <NoteForm
        control={control}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        reset={reset}
        defaultValues={noteFormDefaultValuesGet(note)}
        editorRef={editorRef}
      />

      <DivNav>
        <NoteSingleNav note={note} hideEditNav={true} />
      </DivNav>
    </DivNote>
  );
};

const DivNote = styled.div`
  display: flex;
  flex-direction: column;
`;

const DivNav = styled.div`
  margin-top: 1rem;
`;

export default NoteEdit;
