import React from "react";
import TinyMCEEditor from "components/ui/TinyMCEEditor";
import styled from "styled-components";

const EditorTest = () => {
  const editorRef = React.useRef();
  const contentTextInitial = "";
  const TINY_MCE_INITIAL_HEIGHT: number = 300;

  return (
    <DivField>
      <TinyMCEEditor
        initialHeight={TINY_MCE_INITIAL_HEIGHT}
        editorRef={editorRef}
        initialValue={contentTextInitial}
      />
    </DivField>
  );
};

export const DivField = styled.div`
  margin-top: 0.2rem;
  &&& > div {
    width: 50%;
  }
`;

export default EditorTest;
