import React from "react";
import "./App.css";
import NotesMainContent from "./layout/NotesMainContent";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <NotesMainContent />
    </QueryClientProvider>
  );
}

export default App;
