export function noteTextShort(noteText: string, lineMax: number = 4): string {
  let result = noteText;
  let ind = noteText.indexOf("\n");
  if (ind >= 0) {
    for (let i = 2; i <= lineMax; i++) {
      const ind2 = noteText.indexOf("\n", ind + 1);
      if (ind2 >= 0) {
        ind = ind2;
      } else {
        break;
      }
    }
  }

  if (ind >= 0) {
    result = result.substring(0, ind);
  }

  console.log("noteText", ind, noteText);

  return result;
}
