import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import NoteEdit from "./NoteEdit";
import NoteRead from "./NoteRead";
import { useNoteGet } from "hooks/note/useNoteGet";

const NoteComponent = () => {
  const { noteId } = useParams();
  const [searchParams] = useSearchParams();
  const [mode, setMode] = useState<string>("");
  const note = useNoteGet(noteId as string);
  useEffect(() => {
    const paramsMode = searchParams.get("mode") ?? "";
    if (paramsMode !== mode) {
      setMode(paramsMode);
    }
  }, [searchParams, note, mode]);

  if (!note) {
    return null;
  }

  return (
    <>
      {mode !== "edit" && <NoteRead note={note} />}
      {mode === "edit" && <NoteEdit note={note} />}
    </>
  );
};

export default NoteComponent;
