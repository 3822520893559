import { useMutation } from "@tanstack/react-query";
import { noteAdd, NoteAddParams } from "common/api/note/note_add";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";
import { useNavigate } from "react-router-dom";

type UseNoteAddParams = {
  invalidateKeys?: string[];
  successHandle?: Function;
};

export const useNoteAdd = (hookParams?: UseNoteAddParams) => {
  const navigate = useNavigate();
  const { invalidateQueriesByKeys } = useInvalidateQueryByKeys();
  const { mutate: noteAddMutate } = useMutation({
    mutationFn: async (channelAddParams: NoteAddParams) =>
      await noteAdd(channelAddParams),
    onSuccess: (response) => {
      if (hookParams?.invalidateKeys) {
        invalidateQueriesByKeys(hookParams.invalidateKeys);
      }

      if (hookParams?.successHandle) {
        hookParams.successHandle(response);
      }

      navigate("/");
    },
  });

  return { noteAddMutate };
};
