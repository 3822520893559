import React from "react";
import styled from "styled-components";
import { NoteResult } from "common/note/dto/NoteResult";
import NoteItemView from "./NoteItemView";
import { SCREEN_WIDTH_BREAKPOINT } from "constant/common_constants";
import { NavLink } from "react-router-dom";
import { noteLinkSingle } from "common/note/note_link_common";

type NoteItemProps = {
  note: NoteResult;
  onNoteClick: (note: NoteResult) => void;
};

const NoteItem = ({ note, onNoteClick }: NoteItemProps) => {
  return (
    <>
      <Div key={`note_${note.id}`} onClick={() => onNoteClick(note)}>
        <NoteItemView note={note} />
      </Div>
      <NavLinkMob to={noteLinkSingle(note.id)} key={`note_m_${note.id}`}>
        <NoteItemView note={note} />
      </NavLinkMob>
    </>
  );
};

const Div = styled.div`
  display: none;
  @media (min-width: ${SCREEN_WIDTH_BREAKPOINT}) {
    display: block;
  }
`;

const NavLinkMob = styled(NavLink)`
  display: block;
  @media (min-width: ${SCREEN_WIDTH_BREAKPOINT}) {
    display: none;
  }
  &&& {
    text-decoration: none;
  }
`;

export default NoteItem;
