import { useMutation } from "@tanstack/react-query";
import { noteDelete } from "common/api/note/note_delete";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";
import { useNavigate } from "react-router-dom";

type UseNoteDeleteParams = {
  invalidateKeys?: string[];
};

export const useNoteDelete = (hookParams?: UseNoteDeleteParams) => {
  const navigate = useNavigate();

  const { invalidateQueriesByKeys } = useInvalidateQueryByKeys();
  const { mutate: noteDeleteMutate } = useMutation({
    mutationFn: async (id: string) => await noteDelete(id),
    onSuccess: () => {
      if (hookParams?.invalidateKeys) {
        invalidateQueriesByKeys(hookParams.invalidateKeys);
      }
      navigate("/");
    },
  });

  return { noteDeleteMutate };
};
