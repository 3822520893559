import { useQuery } from "@tanstack/react-query";
import { noteGet } from "common/api/note/note_get";
import { QUERY_KEYS } from "constant/react_query";

export const useNoteGet = (id: string) => {
  const { data: note } = useQuery({
    queryKey: [QUERY_KEYS.NOTE_GET, id],
    queryFn: async () => {
      const result = await noteGet(id);
      return result;
    },
    staleTime: Infinity,
  });
  const result = typeof note !== "undefined" && note !== "" ? note : null;
  return result;
};
