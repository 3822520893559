import { authCurrentGet } from "common/auth/auth_api_common";
import { axiosNotes } from "../axios/notes_axios";

// Set Default Author = email
import { useAuthCurrent } from "hooks/auth/auth_current";

export type NoteAddParams = {
  title: string;
  author?: string;
  note: string;
};

export const noteAdd = async ({
  title,
  author,
  note,
}: NoteAddParams): Promise<string> => {
  let result: string = "";
  let response: any;
  try {

    // Fetch the current user's email if the author is not provided
    if (!author) {
      const authCurrent = await authCurrentGet(); // Fetch user details from the API
      author = authCurrent?.userViewEmail || "Anonymous"; // Fallback to 'Anonymous'
    }
    
    response = await axiosNotes.post("notes/add", {
      title,
      author,
      note,
    });
  } catch (error) {}
  if (response && response.status === 201) {
    result = response.data;
  }

  return result;
};
