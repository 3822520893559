import React from "react";
import SearchPageNavigation from "./SearchPageNavigation";

export type PageNavigation = {
  resTotal: number;
  skip: number;
  limit: number;
  pageNavigationHandle: Function;
};

export type SearchResultListProps<T> = {
  renderSingle: Function;
  results: T[];
  pageNavigation?: PageNavigation | null;
};

function SearchResultList<T>({
  renderSingle,
  results,
  pageNavigation,
}: SearchResultListProps<T>) {
  return (
    <>
      {pageNavigation && <SearchPageNavigation {...pageNavigation} />}
      {results.length > 0 && (
        <div>
          {results.map((result: T) => {
            return renderSingle(result);
          })}
        </div>
      )}
    </>
  );
}

export default SearchResultList;
