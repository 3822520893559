import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import AddIcon from "@mui/icons-material/Add";
import EditNoteIcon from "@mui/icons-material/EditNote";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import LogoutIcon from "@mui/icons-material/Logout";

import styled from "styled-components";

// Mkrstic, to show User Login in Menu
import { useAuthCurrent } from "hooks/auth/auth_current";
import { authLogout } from "common/auth/auth_api_common";

const logout: string = process.env.REACT_APP_LOGOUT ?? "";
console.log("LOGOUT_URL: " + logout);

type MainNavigationDrawerProps = {
  showMenu: boolean;
};

const MainNavigationDrawer = ({ showMenu }: MainNavigationDrawerProps) => {
  const authCurrent = useAuthCurrent();
  const navigate = useNavigate();

  const logoutHandle = async () => {
    console.log("logout");

    try {
      const success = await authLogout();
      if (success) {
        console.log("Successfull logout");
        navigate("/login");
      }
    } catch (err) {}
  };

  return showMenu ? (
    <div>
      <Toolbar />
      <DivLogin id="BigMainMenu">
        <img src="/images/notetake_gradient.png" height="48px"/>
        <p />
        
        {authCurrent?.userViewName ?? ""}
        <br />
        <span style={{ fontSize: "0.8em" }}>
          {authCurrent?.userViewEmail ?? ""}
        </span>
        <br/>
        
      </DivLogin>

      <Divider />
      {
        <>
          <List>
            <ListItem key={"home"} disablePadding>
              <HomeIcon id="NavIcon" />
              <NavLinkItem to="/">
                <ListItemButton>
                  <ListItemText primary="Home" />
                </ListItemButton>
              </NavLinkItem>
            </ListItem>
          </List>
          <Divider />
          {/*<List>
            <ListItem key={"notes"} disablePadding>
              <EditNoteIcon id="NavIcon" />
              <NavLinkItem to="/note/list">
                <ListItemButton>
                  <ListItemText primary="Notes" />
                </ListItemButton>
              </NavLinkItem>
            </ListItem>
          </List>
          <Divider />
          */}
          <List>
            <ListItem key={"new"} disablePadding>
              <AddIcon id="NavIcon" />
              <NavLinkItem to="/note/new">
                <ListItemButton>
                  <ListItemText primary="New" />
                </ListItemButton>
              </NavLinkItem>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem key={"about"} disablePadding>
              <QuestionMarkIcon id="NavIcon" />
              <NavLinkItem to="/about">
                <ListItemButton>
                  <ListItemText primary="About" />
                </ListItemButton>
              </NavLinkItem>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem key={"logout"} disablePadding>
              <LogoutIcon id="NavIcon" />
              <ListItemButton>
                <ListItemText primary="Logout" onClick={logoutHandle} />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem key={"about"} disablePadding>
              <QuestionMarkIcon id="NavIcon" />
              <NavLinkItem to="/terms">
                <ListItemButton>
                  <ListItemText primary="Terms of Service" />
                </ListItemButton>
              </NavLinkItem>
            </ListItem>
          </List>
        </>
      }
    </div>
  ) : null;
};

const DivUserView = styled.div`
  font-size: 1rem;
  text-align: left;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  margin-bottom: 1.5rem;

  img {
    height: 96px;
  }
`;

const DivLogin = styled.div`
  font-size: 1rem;
  text-align: left;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  margin-bottom: 1.5rem;
`;


const DivUserViewName = styled.div`
  margin-top: 1rem;
`;

const DivUserEmail = styled.div`
  margin-top: 0.2rem;
  font-size: 0.8rem;
`;

const NavLinkItem = styled(NavLink)`
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: inherit;
`;

export default MainNavigationDrawer;
