import { NoteResult } from "common/note/dto/NoteResult";
import SearchResultList from "components/common/SearchResultList";
import NoteRead from "../Note/NoteRead";
import { useState, useEffect } from "react";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";
import { QUERY_KEYS } from "constant/react_query";
import { useNoteSearch } from "hooks/note/useNoteSearch";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Height } from "@mui/icons-material";
import { noteInList } from "./NoteList.logic";
import NoteItem from "./NoteItem";

const NoteList = () => {
  const noteListResult = useNoteSearch();
  const invalidateQuery = useInvalidateQueryByKeys();

  useEffect(() => {
    invalidateQuery.invalidateQueriesByKeys([QUERY_KEYS.NOTE_LIST_GET]);
  }, [invalidateQuery]);

  const [selectedNote, setSelectedNote] = useState<NoteResult | null>(null);

  // Set the first note to be selected if available.
  // Exclude note from teh view if it not anymore in result list.
  useEffect(() => {
    if (selectedNote === null) {
      if (noteListResult !== null && noteListResult.length >= 0) {
        setSelectedNote(noteListResult[0]);
      }
    } else {
      if (!noteInList(selectedNote, noteListResult)) {
        setSelectedNote(null);
      }
    }
  }, [selectedNote, noteListResult]);

  const handleNoteClick = (note: NoteResult) => {
    setSelectedNote(note);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: 0,
        height: "100%",
        margin: 0,
        paddingBottom: 0,
      }}
    >
      {/* Row */}
      <Grid
        container
        spacing={2}
        sx={{
          height: "100%",
        }}
      >
        {/* Column */}
        {noteListResult !== null && (
          <Grid item xs={12} sm={6} md={3}>
            <h1> Notes</h1>
            <b>{noteListResult.length} notes</b>
            <Box sx={{ p: 1, marginTop: "1rem" }}>
              <SearchResultList<NoteResult>
                renderSingle={(note: NoteResult) => (
                  <NoteItem note={note} onNoteClick={handleNoteClick} />
                )}
                results={noteListResult}
              />
            </Box>
          </Grid>
        )}
        <Grid id="Note" item xs={12} sm={6} md={9}>
          <Box
            sx={{
              boxShadow: "0px 10px 12px rgba(0, 0, 0, 0.5)", // Subtle shadow
              borderRadius: 2, // Optional: Adds rounded corners
              padding: 2, // Optional: Adds padding inside the card
              height: "90%",
            }}
          >
            {selectedNote ? (
              <NoteRead note={selectedNote} />
            ) : (
              <p>Select a note to view details</p>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NoteList;
