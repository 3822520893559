import { NAV_ELEMENTS } from "constant/navigation";
import { MainNavElement } from "type/navigation/main_navigation.type";

export const GENERAL_NAV_ELEMENTS: MainNavElement[] = [NAV_ELEMENTS.ABOUT];
export const NOTE_NAV_ELEMENTS: MainNavElement[] = [
  NAV_ELEMENTS.NOTE_NEW,
  NAV_ELEMENTS.NOTE_LIST,
];

export const MAIN_NAV_DRAWER_WIDTH = 240;
