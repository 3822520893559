import React, { useCallback, useState } from "react";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import NavItemSmallScreen from "./NavItemSmallScreen";
import { MainNavElement } from "type/navigation/main_navigation.type";
import LogoutNav from "components/navigation/LogoutNav";
import { NAV_ELEMENTS } from "constant/navigation";
import AddIcon from "@mui/icons-material/Add";
import EditNoteIcon from '@mui/icons-material/EditNote';
import HelpIcon from '@mui/icons-material/Help';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuthCurrent } from "hooks/auth/auth_current";

type NavItemProps = {
  navItem: MainNavElement;
};

const MainNavigationSmallScreen = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  // const isAdmin: boolean = useIsAdmin();
  const isAdmin: boolean = true;
  const authCurrent = useAuthCurrent();


  const clickHandle = useCallback(() => setShowMenu(false), []);

  const menuShowClickHandle = () => {
    setShowMenu((currentValue: boolean) => {
      return !currentValue;
    });
  };

  const NavItem = ({ navItem }: NavItemProps) => {
    return (
      <NavItemSmallScreen
        path={navItem.path}
        title={navItem.title}
        clickHandle={clickHandle}
      />
    );
  };

  return (
    
    <DivSmallNav>
      <DivHeader>
        <Brand href="/">
          Not&AElig;ke<br/>
          <span id="SmallEmail">{authCurrent?.userViewEmail ?? "guest"}
          </span>
        </Brand>
        <DivButton>
          <IconButton
            id="SmallMenuIcon"
            aria-label="navigation bars"
            onClick={menuShowClickHandle}
          >
            <FontAwesomeIcon icon={faBars as any} />
          </IconButton>
        </DivButton>
      </DivHeader>
      
        
        {showMenu && (
          <DivMenu id="MobileMenu">
            <NavItem navItem={NAV_ELEMENTS.START} />
            <NavItem navItem={NAV_ELEMENTS.NOTE_NEW} />
            <NavItem navItem={NAV_ELEMENTS.NOTE_LIST} />
            <NavItem navItem={NAV_ELEMENTS.ABOUT} />
            <NavItem navItem={NAV_ELEMENTS.LOGOUT} />
          </DivMenu>
        )}
      
    </DivSmallNav>
  );
};

const DivSmallNav = styled.div.attrs({ className: "screen-small" })`
  
  width: 100%;
  background-color: rgba(118, 65, 65, 0.9); /* Set navbar background color */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5); /* Drop shadow */
  padding: 0.5rem 1rem; /* Add padding for spacing */
  position: sticky; /* Optional: Make it stick to the top */
  top: 0;
  z-index: 1000; /* Ensure it stays above other elements */
  color: rgb(65, 118, 118);
`;

const Brand = styled.a`
  
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
  color: white; /* Adjust color as needed */
`;

const DivHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;

`;

const DivMenu = styled.div`
  position: absolute;
  top: 100%;
  right: 1.5rem;
  display: flex;
  flex-direction: column;
  margin-top: 0.0rem;
  margin-right: 0.0rem;
  padding-right: 1.5rem;
  max-width: calc(100vw - 1rem); /* Prevent the menu from going outside the viewport */
  width: 250px; /* Default width for the dropdown menu */
  overflow-x: hidden; /* Prevent horizontal overflow */
  overflow-y: auto; /* Allow vertical scrolling if content exceeds height */
  z-index: 999;
  
`;

const DivButton = styled.div`
  margin-right: 1.5rem;
  text-align: right;
`;

export default MainNavigationSmallScreen;
