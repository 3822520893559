import { useQuery } from "@tanstack/react-query";
import { noteListGet } from "common/api/note/note_get";
import { QUERY_KEYS } from "constant/react_query";

export const useNoteSearch = () => {
  const { data: note } = useQuery({
    queryKey: [QUERY_KEYS.NOTE_LIST_GET],
    queryFn: async () => {
      const result = await noteListGet();
      return result;
    },
    staleTime: Infinity,
  });
  const result = typeof note !== "undefined" && note !== "" ? note : null;
  return result;
};
