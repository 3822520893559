import axios, { CreateAxiosDefaults } from "axios";

export function axiosInstanceCreate(
  baseURL: string,
  options?: CreateAxiosDefaults<any>
) {
  // Mkrstic
  const axiosInstance = axios.create({ baseURL, withCredentials: true, ...options });
  return axiosInstance;
}
