import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";

export default function LoginView() {
  return (
    <Div>
      <Outlet />
    </Div>
  );
}

const Div = styled.div`
  display: flex;
  margin-top: 0rem;
  flex-direction: column;
  color: #764141;
`;
