export const noteLinkSingle = (
  noteId: string,
  isEditMode: boolean = false
): string => {
  let link = `/note/${noteId}`;
  if (isEditMode) {
    link += "?mode=edit";
  }
  return link;
};
