import { axiosNotes } from "../axios/notes_axios";

export const noteUpdate = async (
  id: string,
  updatePayload: any
): Promise<string> => {
  let result: string = "";
  let response: any;
  try {
    response = await axiosNotes.put(`notes/update/${id}`, updatePayload);
  } catch (error) {}
  if (response && response.status === 200) {
    result = response.data;
  }

  return result;
};
