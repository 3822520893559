import styled from "styled-components";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import { NavLink } from "react-router-dom";
import { QUERY_KEYS } from "constant/react_query";
import useConfirm from "common/modal/ConfirmDialog";
import { NoteResult } from "common/note/dto/NoteResult";
import { useNoteDelete } from "hooks/note/useNoteDelete";
import { noteLinkSingle } from "common/note/note_link_common";

type NoteSingleNavProps = {
  note: NoteResult;
  hideEditNav?: boolean;
  hideReadNav?: boolean;
};

const NoteSingleNav = ({
  note,
  hideEditNav,
  hideReadNav,
}: NoteSingleNavProps) => {
  const { noteDeleteMutate } = useNoteDelete({
    invalidateKeys: [QUERY_KEYS.NOTE_LIST_GET],
  });

  const actionConfirm = useConfirm();

  const deleteHandle = async () => {
    const check = await actionConfirm({
      title: "Note",
      message: "Are you sure you want to delete note?",
    });
    if (check) {
      noteDeleteMutate(note.id);
    }
  };

  return (
    <DivNav>
      <DeleteIcon id="GreenIcon" onClick={deleteHandle} />
      {!hideEditNav && (
        <NavLink to={noteLinkSingle(note.id, true)}>
          <EditIcon id="GreenIcon"/>
        </NavLink>
      )}
      {!hideReadNav && (
        <NavLink to={noteLinkSingle(note.id)}>
          <ReadMoreIcon id="GreenIcon" />
        </NavLink>
      )}
    </DivNav>
  );
};

const DivNav = styled.div`
  margin-top: 0.75rem;
  svg {
    margin-left: 1rem;
    cursor: pointer;
  }
  svg:hover {
    opacity: 0.8;
  }
  &&& > svg:first-of-type {
    margin-left: 0;
  }
`;

export default NoteSingleNav;
