import React, { useRef } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { QUERY_KEYS } from "constant/react_query";
import { useNavigate } from "react-router-dom";
import { noteFormDefaultValuesGet } from "common/note/note_form_common";
import { useNoteAdd } from "hooks/note/useNoteAdd";
import NoteForm from "components/common/note/NoteForm";

const NoteNew = () => {
  const { handleSubmit, reset, control } = useForm({
    defaultValues: noteFormDefaultValuesGet(),
  });
  const navigate = useNavigate();
  const { noteAddMutate } = useNoteAdd({
    invalidateKeys: [QUERY_KEYS.NOTE_LIST_GET],
    successHandle: (id: any) => {
      console.log("add success", id);
      navigate(`/note/list`);
    },
  });
  const editorRef = React.useRef<any>("");

  const onSubmit = async (data: any) => {
    const { title, author } = data;

    noteAddMutate({
      title,
      author,
      note: editorRef.current.getContent(),
    });
    toast.success("New channel added");
  };

  return (
    <>
      <div>Note Add</div>
      <Div>
        <NoteForm
          control={control}
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          reset={reset}
          editorRef={editorRef}
        />
      </Div>
    </>
  );
};

const Div = styled.div`
  display: flex;
  flex-direction: column;
`;

export default NoteNew;
