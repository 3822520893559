import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import { ReactRefI } from "type/ref.type";
import InputField from "components/ui/InputField";
import TinyMCEEditor from "components/ui/TinyMCEEditor";
import { TINY_MCE_INITIAL_HEIGHT } from "./NoteForm.const";

// Mkrstic, Set Default Author = email
import { useAuthCurrent } from "hooks/auth/auth_current";

type NoteFormProps = {
  control: any;
  handleSubmit: Function;
  onSubmit: Function;
  reset: Function;
  defaultValues?: any;
  editorRef: ReactRefI;
};

const NoteForm = ({
  onSubmit,
  handleSubmit,
  reset,
  defaultValues,
  control,
  editorRef,
}: NoteFormProps) => {
  const authCurrent = useAuthCurrent();
  React.useEffect(() => {
    console.log("defaultValues", defaultValues);
  }, [defaultValues]);
  const titleDefault = defaultValues?.title ?? "";
  //const authorDefault = defaultValues?.author ?? "";
  const authorDefault = authCurrent?.userViewEmail ?? "";
  const noteDefault = defaultValues?.note ?? "";

  return (
    <DivForm>
      <form>
        <DivField style={{ marginBottom: "0.5rem" }}>
          <InputField
            name="title"
            control={control}
            label="title"
            defaultValue={titleDefault}
          />
        </DivField>

        <TinyMCEEditor
          //initialHeight={TINY_MCE_INITIAL_HEIGHT}
          editorRef={editorRef}
          initialValue={noteDefault}
        />
        <DivNav>
          <Button onClick={handleSubmit(onSubmit)} type="submit">
            Submit
          </Button>
          <Button onClick={() => reset()} variant={"outlined"}>
            Reset
          </Button>
        </DivNav>
        <DivField id="InputAuthor">
          <InputField
            name="author"
            control={control}
            label="author"
            defaultValue={authorDefault}
          />
        </DivField>
      </form>
    </DivForm>
  );
};

const DivForm = styled.div`
  margin-top: 0.75rem;
  width: 100%;
`;

const DivField = styled.div`
  margin-top: 1rem;
  width: 100%;
  &&& div {
    width: 100%;
  }
`;

const DivNav = styled.div`
  margin-top: 2rem;
`;

export default NoteForm;
