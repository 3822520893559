import React, { useEffect } from "react";
import styled from "styled-components";
import parse from "html-react-parser";
import NoteSingleNav from "components/common/note/NoteSingleNav/NoteSingleNav";
import { NoteResult } from "common/note/dto/NoteResult";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";
import { QUERY_KEYS } from "constant/react_query";
import Prism from "prismjs";
import "prismjs/themes/prism.css";
import "prismjs/components/prism-bash";
import "prismjs/components/prism-javascript";
type NoteReadProps = {
  note: NoteResult;
};

const NoteRead = ({ note }: NoteReadProps) => {
  const invalidateQuery = useInvalidateQueryByKeys();
  useEffect(() => {
    invalidateQuery.invalidateQueriesByKeys([QUERY_KEYS.NOTE_GET]);
  }, [invalidateQuery]);

  useEffect(() => {
    Prism.highlightAll();
  }, [note]);

  if (!note) {
    return <h1>No Notes in DB</h1>; // Fallback UI
  }

  return (
  <div id="NoteRead">
    <DivNav>
        <NoteSingleNav note={note} hideReadNav={true} />
      </DivNav>
    <div>
      <div><h1>{note.title}</h1></div>
      <div>{note.author}</div>
      <div>{parse(note.note)}</div>
      
    </div>
    
  </div>
  );
};

const DivNav = styled.div`

`;

export default NoteRead;
