import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NotesStart from "components/pages/NotesStart";
import MainView from "./MainView";
import LoginView from "./LoginView";
import LoginPage from "components/pages/LoginPage";
import EditorTest from "components/pages/EditorTest";
import About from "components/pages/About";
import LogoutPage from "components/pages/LogoutPage";
import NoteNew from "components/pages/note/NoteNew";
import NoteList from "components/pages/note/NoteList";
import { ConfirmDialogProvider } from "common/modal/ConfirmDialog";
import Note from "components/pages/note/Note";
import TermsOfService from "components/pages/TermsOfService";

export default function NotesMainContent() {
  return (
    <ConfirmDialogProvider>
      <Router>
        <Routes>
          <Route element={<MainView />}>
            <Route path="/" element={<NoteList />} />
            <Route path="/editor-test" element={<EditorTest />} />
            <Route path="/about" element={<About />} />
            <Route path="/note">
              <Route path="new" element={<NoteNew />} />
              {/** <Route path="list" element={<NoteList />} /> **/}
              <Route path=":noteId" element={<Note />} />
            </Route>
          </Route>
          <Route element={<LoginView />}>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/logout" element={<LogoutPage />} />
            <Route path="/terms" element={<TermsOfService />} />
          </Route>
        </Routes>
      </Router>
    </ConfirmDialogProvider>
  );
}
