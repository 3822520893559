import { NoteResult } from "./dto/NoteResult";

export const noteFormDefaultValuesGet = (note?: NoteResult) => {
  if (note) {
    return {
      title: note.title,
      author: note.author,
      note: note.note,
    };
  } else {
    return {
      title: "",
      author: "",
      note: "",
    };
  }
};
