import styled from "styled-components";
import { NoteResult } from "common/note/dto/NoteResult";
import { Box, Grid, Card, CardContent, Typography } from "@mui/material";
import parse from "html-react-parser";
import { noteTextShort } from "./NoteItem.logic";

type NoteItemViewProps = {
  note: NoteResult;
};

const NoteItemView = ({ note }: NoteItemViewProps) => {
  const formattedUpdatedAt = new Date(note.updatedAt).toLocaleDateString();

  return (
    <Box sx={{ flexGrow: 1, paddingBottom: 0 }}>
      <Grid container spacing={2}>
        {/* Column */}
        <div key={note.id} id="CardDiv">
          <Grid item xs={12}>
            <Card
              sx={{
                marginTop: "1rem",
                backgroundColor: "transparent",
                display: "flex",
                flexDirection: "column",
                boxShadow: "none",
                width: "100%",
              }}
            >
              <CardContent
                id="NoteItem"
                sx={{
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "row", // Horizontal layout for title and date
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "100%",
                  cursor: "pointer",
                }}
              >
                <DivNote>
                  <DivNoteHeader>
                    {/* Title */}
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        color: "rgb(65, 118, 118)",
                        flexGrow: 1, // Allows the title to take available space
                        marginRight: 2, // Adds spacing between title and date
                      }}
                    >
                      {note.title}
                    </Typography>

                    {/* Date */}
                    <Typography
                      variant="caption"
                      sx={{
                        color: "rgb(100, 100, 100)", // Adjust color for visibility
                      }}
                    >
                      {new Date(note.updatedAt).toLocaleDateString()}
                    </Typography>
                  </DivNoteHeader>
                  <DivNoteShortContent>
                    {parse(noteTextShort(note.note, 2))}
                  </DivNoteShortContent>
                </DivNote>
              </CardContent>
            </Card>
            <hr />
          </Grid>
        </div>
      </Grid>
    </Box>
  );
};

const DivNote = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DivNoteHeader = styled.div`
  display: flex;
  align-content: space-between;
  width: 100%;
`;

const DivNoteShortContent = styled.div`
  margin-top: 0.5rem;
`;

export default NoteItemView;
