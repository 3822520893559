import styled from "styled-components";

const About = () => {
  return (
    <DivAbout>
      <div >
  <h1>Technical Description</h1>
  
  <h2>Overview</h2>
  <p>
    This document outlines the technical architecture, features, and technologies used in the development 
    of the online note-taking app. The project showcases full-stack development, cloud deployment, 
    monitoring, and modern software engineering practices.
  </p>

  <h2>Key Features</h2>
  <ul>
    <li>
      <strong>User Authentication:</strong>
      <ul>
        <li>Secure login using Google OAuth via Passport.js.</li>
        <li>Persistent user sessions with Redis for session management.</li>
      </ul>
    </li>
    <li>
      <strong>Note Management:</strong>
      <ul>
        <li>CRUD (Create, Read, Update, Delete) operations for notes.</li>
        <li>Rich-text formatting for notes using a WYSIWYG editor.</li>
        <li>Tags and categories for better organization.</li>
      </ul>
    </li>
    <li>
      <strong>Responsive UI:</strong>
      Fully responsive design for mobile and desktop platforms using React and Material UI.
    </li>
    <li>
      <strong>Data Security:</strong>
      End-to-end encryption for note storage and user data.
    </li>
    <li>
      <strong>Scalability:</strong>
      Horizontal scalability using Kubernetes.
    </li>
  </ul>

  <h2>Technical Architecture</h2>
  <h3>Frontend</h3>
  <ul>
    <li><strong>Framework:</strong> React</li>
    <li><strong>Styling:</strong> Material UI</li>
    <li><strong>State Management:</strong> Context API</li>
    <li><strong>Libraries:</strong> Axios for API communication, React Router for client-side routing.</li>
  </ul>

  <h3>Backend</h3>
  <ul>
    <li><strong>Runtime:</strong> Node.js</li>
    <li><strong>Framework:</strong> Express.js</li>
    <li><strong>Authentication:</strong> Passport.js with Google OAuth2.0</li>
    <li><strong>Database:</strong> MongoDB for data storage (with Mongoose ODM)</li>
    <li><strong>Caching:</strong> Redis for session and data caching</li>
    <li><strong>Logging:</strong> Log4js for structured logging</li>
  </ul>

  <h3>API Design</h3>
  <p>RESTful APIs designed with OpenAPI standards. Example endpoints:</p>
  <ul>
    <li><code>GET /api/notes</code>: Fetch all notes for the logged-in user.</li>
    <li><code>POST /api/notes</code>: Create a new note.</li>
  </ul>

  <h2>DevOps & Deployment</h2>
  <ul>
    <li><strong>Version Control:</strong> GitLab for source code management</li>
    <li><strong>CI/CD:</strong> GitLab CI/CD pipelines for automated testing, building, and deploying</li>
    <li><strong>Containerisation:</strong> Docker for consistent environment replication</li>
    <li><strong>Deployment:</strong> Kubernetes cluster on Hetzner Cloud</li>
    <li><strong>Ingress:</strong> Nginx Ingress Controller for routing</li>
    <li><strong>TLS:</strong> Automated Let's Encrypt certificates using Cert-Manager</li>
  </ul>

  <h2>Monitoring & Logging</h2>
  <ul>
    <li><strong>Infrastructure Monitoring:</strong> Prometheus and Grafana for real-time metrics and alerting</li>
    <li>
      <strong>Application Performance Monitoring (APM):</strong>
      OpenTelemetry SDK integrated into the application for detailed traceability and performance insights
    </li>
    <li>
      <strong>Tracing:</strong> Distributed tracing collected via OpenTelemetry and sent to Grafana Tempo, enabling 
      end-to-end visibility into requests
    </li>
    <li>
      <strong>Logging:</strong> Application logs are centralized in Grafana Loki, allowing for efficient debugging 
      and analysis
    </li>
    <li>
      <strong>Visualization:</strong> A unified Grafana dashboard integrates metrics, traces, and logs for 
      comprehensive monitoring and debugging
    </li>
  </ul>

  <h2>Tools and Technologies</h2>
  <ul>
    <li><strong>Languages:</strong> JavaScript (Frontend & Backend), TypeScript (for type safety in backend APIs)</li>
    <li><strong>Libraries/Frameworks:</strong> React, Material UI, Express.js, Passport.js, Mongoose</li>
    <li><strong>Infrastructure:</strong> Docker, Kubernetes, Cert-Manager, GitLab CI/CD, Redis, MongoDB</li>
    <li><strong>Cloud Services:</strong> Kubernetes deployed on Hetzner Cloud</li>
    <li>
      <strong>Monitoring:</strong> Full solution based on OpenTelemetry stack with Otel Collector (Contrib), 
      Prometheus, Grafana Loki, Grafana Tempo, and Grafana. Monitoring is covering infrastructure and APM.
    </li>
  </ul>

  <h2>Lessons Learned</h2>
  <ul>
    <li>Improved understanding of full-stack development workflows</li>
    <li>Deepened knowledge of cloud deployment and monitoring practices</li>
    <li>
      Gained hands-on experience in implementing security features like OAuth2.0 and session management
    </li>
  </ul>

  <h2>Future Enhancements</h2>
  <ul>
    <li>Add support for offline mode using IndexedDB</li>
    <li>Introduce AI-powered note summarization and keyword suggestions</li>
    <li>Enhance scalability with microservices architecture</li>
  </ul>
</div>

    </DivAbout>
  );
};

const DivAbout = styled.div`
`;

export default About;